import './styles.css';
import React from "react";

function About() {
    return (
        <div className={"divComp"}>
            <p className={"p"}>copyright by johannes staedtler</p>
        </div>





    );
}

export default About;